import { template as template_9cc8bb72491a41c888284c9211bb1c42 } from "@ember/template-compiler";
const FKLabel = template_9cc8bb72491a41c888284c9211bb1c42(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

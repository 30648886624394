import { template as template_198c1a80dd794b039a1478c7c29dd477 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_198c1a80dd794b039a1478c7c29dd477(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;

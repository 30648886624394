import { template as template_10fc0c4da6a54e128d4cc37b7e9d8dd7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_10fc0c4da6a54e128d4cc37b7e9d8dd7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
